import React from 'react';

export default () => (
  <svg
    // width="90.994"
    // height="22.513"
    viewBox="0 0 90.994 22.513">
    <g transform="translate(0 0)">
      <path
        d="M180.161,288.488h-4.71v-22.42h4.71a5.47,5.47,0,0,1,2.186.444,5.559,5.559,0,0,1,2.969,2.971,5.5,5.5,0,0,1,.444,2.193,5.425,5.425,0,0,1-.688,2.7,5.8,5.8,0,0,1-1.82,1.991,6.466,6.466,0,0,1,2.463,2.337,6.462,6.462,0,0,1,.427,5.849,6.546,6.546,0,0,1-3.458,3.436A6.324,6.324,0,0,1,180.161,288.488Zm-3.028-12.876,3.028-.033h.154a3.912,3.912,0,0,0,1.452-.335,3.85,3.85,0,0,0,1.205-.847,3.959,3.959,0,0,0,.814-1.224,4,4,0,0,0-.017-3.028,4,4,0,0,0-2.079-2.086,3.768,3.768,0,0,0-1.531-.306h-3.028Zm0,11.194h3.028a4.59,4.59,0,0,0,1.879-.382,4.793,4.793,0,0,0,2.539-2.539,4.53,4.53,0,0,0,.384-1.851,4.621,4.621,0,0,0-.368-1.834,4.948,4.948,0,0,0-.992-1.5,4.868,4.868,0,0,0-3.289-1.44h-3.182Z"
        transform="translate(-175.451 -266.006)"
        fill="#fff"
      />
      <path
        d="M189.963,288.555H182.1V266.042h1.682v20.83h6.179Z"
        transform="translate(-166.32 -266.042)"
        fill="#fff"
      />
      <path
        d="M192.946,288.5a5.461,5.461,0,0,1-2.188-.441,5.519,5.519,0,0,1-1.775-1.21,5.891,5.891,0,0,1-1.194-1.789,5.459,5.459,0,0,1-.441-2.186V271.652a5.478,5.478,0,0,1,.441-2.188,5.556,5.556,0,0,1,2.969-2.964,5.609,5.609,0,0,1,4.373,0,5.556,5.556,0,0,1,2.969,2.964,5.479,5.479,0,0,1,.444,2.188v11.227a5.46,5.46,0,0,1-.444,2.186,5.892,5.892,0,0,1-1.194,1.789,5.52,5.52,0,0,1-1.775,1.21A5.458,5.458,0,0,1,192.946,288.5Zm0-20.769a3.863,3.863,0,0,0-1.514.306,3.937,3.937,0,0,0-2.1,2.084,3.764,3.764,0,0,0-.306,1.533V282.9a3.779,3.779,0,0,0,.306,1.533,3.987,3.987,0,0,0,.84,1.239,3.932,3.932,0,0,0,1.255.842,3.932,3.932,0,0,0,3.042,0,3.989,3.989,0,0,0,2.079-2.081,3.781,3.781,0,0,0,.308-1.533V271.659a3.765,3.765,0,0,0-.308-1.533,3.971,3.971,0,0,0-.84-1.241,4.036,4.036,0,0,0-1.239-.842A3.831,3.831,0,0,0,192.946,267.736Z"
        transform="translate(-159.116 -266.023)"
        fill="#fff"
      />
      <path
        d="M215.2,266.056l-4.037,22.42h-3.123L204.582,268.9,201.1,288.476h-3.118L194,266.056h1.713l3.7,20.738h.275l3.7-20.738H205.8l3.673,20.738h.273l3.733-20.738Z"
        transform="translate(-149.986 -266.023)"
        fill="#fff"
      />
      <path
        d="M226.061,266.056l-4.037,22.42H218.9L215.447,268.9l-3.486,19.577h-3.118l-3.98-22.42h1.713l3.7,20.738h.275l3.7-20.738h2.416l3.673,20.738h.273l3.733-20.738Z"
        transform="translate(-135.068 -266.023)"
        fill="#fff"
      />
    </g>
  </svg>
);
